<template>
	<div style="background-color: #f5f5f5;">
		<top :windowWidth="windowWidth" :windowHeight="windowHeight"></top>
		<div style="display: flex;">
			<div>
				<el-image style="width: 20vw;" :src="require('@/assets/img/memberList.jpg')" :fit="'cover'"></el-image>
			</div>
			
			<div style="width: 55vw;margin-left: 1vw;">
				<div style="background: #fff;padding: 1vw 3vw;margin-top: 20px;position: relative;display: flex;">
					<div style="text-align: center;">
						<el-image style="width: 4vw;height: 4vw;" :src="require('@/assets/img/head.png')" :fit="'cover'"></el-image>
						<div style="text-align: center;padding-top: 1vw;">您好,{{userInfo.uid}}</div>
					</div>
					
					<div>
						<div style="padding-left: 4vw;font-size: 2vw;font-weight: bold;">客户编号：{{userInfo.uid}}</div>
						<div style="padding-left: 4vw;font-size: 1vw;padding-top: 1vw;">手机号码：{{userInfo.mobile}}</div>
						<div style="padding-left: 4vw;font-size: 1vw;padding-top: 1vw;color: red;">积分：{{userInfo.score}}</div>
					</div>
					
					<div style="padding-left: 10vw;">
						<div style="display: flex;align-items: center;">
							<el-image style="width: 4vw;height: 4vw;" :src="require('@/assets/img/s-head@icon.png')" :fit="'cover'"></el-image>
							<div style="padding-left: 1vw;font-size: 1vw;">
								<div>专属业务顾问</div>
								<div>阁先强</div>
							</div>
						</div>
						<div style="display: flex;padding-top: 0.5vw;align-items: center;">
							<el-image style="width: 2vw;height: 2vw;" :src="require('@/assets/img/phone.png')" :fit="'cover'"></el-image>
							<div style="padding-left: 1vw;">手机/微信：18028762289</div>
						</div>
						<div style="display: flex;padding-top: 0.5vw;align-items: center;">
							<el-image style="width: 2vw;height: 2vw;" :src="require('@/assets/img/QQ.png')" :fit="'cover'"></el-image>
							<div style="padding-left: 1vw;">QQ：154615158</div>
						</div>
						<div style="display: flex;padding-top: 0.5vw;align-items: center;">
							<el-image style="width: 2vw;height: 2vw;" :src="require('@/assets/img/shouhou.png')" :fit="'cover'"></el-image>
							<div style="padding-left: 1vw;">售后电话：18028762289</div>
						</div>
					</div>
				</div>
				
				<div style="padding: 1vw 0;margin-top: 20px;">
					<div style="font-size: 1vw;">积分商城</div>
					<div style="display: flex;">
						<div style="width: 25%;margin-right: 1vw;background:#fff;margin-top: 1vw;">
							<el-image style="width: 100%;height: 10vw;" :src="require('@/assets/img/benci.png')" :fit="'cover'"></el-image>
							<div style="padding: 1vw;">
								<div style="">奔驰C级 2023款运动版</div>
								<div style="color: red;padding-top: 1vw;">积分：43,000,000</div>
							</div>
						</div>
						<div style="width: 25%;margin-right: 1vw;background:#fff;margin-top: 1vw;">
							<el-image style="width: 100%;height: 10vw;" :src="require('@/assets/img/xne.png')" :fit="'cover'"></el-image>
							<div style="padding: 1vw;">
								<div style="">N°5 香奈儿五号香水（经典）</div>
								<div style="color: red;padding-top: 1vw;">积分：890,000</div>
							</div>
						</div>
						<div style="width: 25%;margin-right: 1vw;background:#fff;margin-top: 1vw;">
							<el-image style="width: 100%;height: 10vw;" :src="require('@/assets/img/gcb.png')" :fit="'cover'"></el-image>
							<div style="padding: 1vw;">
								<div style="">Aphrodite系列饰双G肩背包</div>
								<div style="color: red;padding-top: 1vw;">积分：9,800,000</div>
							</div>
						</div>
						<div style="width: 25%;background:#fff;margin-top: 1vw;">
							<el-image style="width: 100%;height: 10vw;" :src="require('@/assets/img/ams.png')" :fit="'cover'"></el-image>
							<div style="padding: 1vw;">
								<div style="">Steeple 28手提包</div>
								<div style="color: red;padding-top: 1vw;">积分：37,000,000</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			
			<div style="width: 25vw;padding-top: 1vw;padding-left: 2vw;padding-right: 2vw;">
				<el-image style="width: 100%;" :src="require('@/assets/img/signIn.png')" :fit="'cover'"></el-image>
			</div>
		</div>
		
	</div>
</template>

<script>
	import top from '@/components/top/index.vue';
	import Cookies from "js-cookie";
	export default {
		components: {
			top
		},
		data() {
			return {
				 userInfo:null,
				 // 浏览器窗口宽度高度
				 windowWidth: 0,
				 windowHeight: 0,
			}
		},
		
		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
		},
		
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
				})();
			};
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		}
	}
</script>

<style lang="less" scoped>
	
</style>